@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,300;0,500;0,700;0,900;1,400&display=swap');

/* @import "primeicons/primeicons.css"; */
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.css";
/* @import url('/src/css/curebay-medicare.webflow.css'); */
/* @import url('/src/css/normalize.css'); */
/* @import url('/src/css/webflow.css'); */

:root {
    font-family: 'Open Sans';
}

/* ol,
ul,
menu {

    margin: 12px 12px !important;
} */

/* ol,
ul,
menu {

    margin: 12px 12px !important;
} */



.p-overlaypanel .p-overlaypanel-content {
    padding: 0px;
}

.p-overlaypanel .p-overlaypanel-close {

    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    right: 1rem;
    background-color: #0272DA;

}


/* #root>div {
    max-width: 1400px;
    margin: auto;
} */


.mt-25 {
    margin-top: 25px;
}

.ht-unset>div {
    height: unset !important;
}

.css-14i49gh-MuiPaper-root-MuiCard-root {
    height: unset !important;
}

.padd-btm-25>div {
    padding-bottom: 25px;
}

.css-qw3skv-MuiPaper-root-MuiCard-root {
    margin-bottom: 0 !important;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.04) !important;

}

.mt-20 {
    margin-top: 20px;
}

.pb-div-btm-20>div {
    padding-bottom: 20px !important;
}

.css-1eg5cz1-MuiButtonBase-root-MuiButton-root {
    text-transform: unset !important;
}

.max-w-1400 {
    max-width: 1400px;
}

.w-90 {
    width: 90%;
}

.list-none {
    list-style-type: unset !important;
}

/* .css-46bh2p-MuiCardContent-root{
    padding: 40px !important;
} */
.pd-card>div>div>div>div {
    padding: 30px !important;
}

.pd-card-40>div>div>div>div {
    padding: 40px !important;
}

.brdr-top-btm-4 {
    border-bottom: 4px solid rgba(57, 109, 221, 0.19);
    border-top: 4px solid rgba(57, 109, 221, 0.19);
    padding-top: 40px;
    padding-bottom: 40px;
}

.css-wa51sd-MuiButtonBase-root-MuiIconButton-root {
    background-color: unset !important;
}

.pb-15 {
    padding-bottom: 15px;
}

.text-align-left {
    text-align: left;
}

.pdl-20 {
    padding-left: 20px;
}

.w-200 {
    width: 200px;
}

.mtr-30 {
    margin-top: 30px;
}

.m-w-1200 {
    max-width: 1200px;
}

.m-w-317 {
    max-width: 317px;
}

.p-25 {
    padding: 25px;
}

.prt-10 {
    padding-right: 10px;
}

.bg-EDFCFF {
    background-color: #EDFCFF;
}

.br-5 {
    border-radius: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.fc-66B889 {
    color: #66B889;
}

.br-1 {
    border: 2px solid #66B889;
}
.br-2 {
    border: 2px solid #ffb400;
}
.br-3 {
    border: 2px solid #2698e2;
}
.br-4 {
    border: 2px solid #1f266d;
}
.br-5 {
    border: 2px solid #e527d1;
}
.br-6 {
    border: 2px solid #f0525296;
}





.br-50 {
    border-radius: 50%;
}

.ht-30 {
    height: 30px;
}

.wd-30 {
    width: 30px;
}

.wd-2 {
    width: 2px;
}

.ht-75 {
    height: 75px;
}

.bg-66B889 {
    background-color: #66B889;
}

.mrlt-15 {
    margin-left: 15px;
}

.pdlt-10 {
    padding-left: 10px;
}

.fc-18406D {
    color: #18406D;
}

.loader {
    border-top-color: #3498db !important;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(min-width:320px) and (max-width:768px) {
    .xs-bg-white {
        background-color: white !important;
        background-image: none !important;
        width: 100% !important;
    }

    .bg-position-right {
        background-position: right;
        background-size: cover !important;
    }

    .xs-h-50 {
        height: 50vh;

    }

    .xs-m-auto {
        margin: 0 auto;
        top: -2rem;
        position: relative;
    }

    .xs-t-3 {
        top: -3rem;
    }

    .xs-overflow-x-hidden {
        overflow-x: hidden;
    }

    .xs-spl-img {
        left: 34px;
        top: -65px;
    }

    .xs-w-full {
        width: 100% !important;
        height: 100% !important;

    }

    body {
        overflow-x: hidden;
        width: 100%;
    }

    .resp-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 10px;
    }

    @media(max-width:600px) {
        .backgroundimgdiv {
            background-color: red;
        }

        /* .backgroundimgdiv{background: linear-gradient(0deg, rgba(206,227,237,1) 34%, rgba(255,255,255,1) 88%);} */

    }

}

.react-tel-input .form-control {
    width: 100% !important;
    height: 2.5rem !important;
   
}
.popupimg{width:210px !important;}
@media(max-width:500px){
    .popupimg{width:163px !important;}  
}
.style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color:white; /* You need to define this variable in your CSS */
    border: 2px solid #000;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.24);
    padding: 16px 17px 16px 7px;
  }
  .slider-wrapper .slider.slide {margin-bottom: 35px;}
  .carousel .thumbs-wrapper {
    display: none;
}
.carousel .control-dots {
    bottom: -18px !important;
}
.carousel .control-dots .dot {
    box-shadow:none !important;
    border: 2px solid #3c93e3 !important;
    width: 10px !important;
    height: 11px !important;
}
.carousel .control-dots .selected {
  border: 1px solid white !important;
    background-color: #3c93e3 !important;
}
.carousel-status{display: none;}

element.style {
    display: none;
}

.scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 2px solid var(--primary-color);
    border: 2px solid var(--primary-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 21px;
    line-height: 28px;
    color: var(--primary-color);
    font-weight: 500;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    outline: none !important;
    transition: 0.3s;
    overflow: hidden;
   
    z-index: 2;
    text-transform: capitalize;
    color: white;
    background-color: #40b882;
    border: 5px solid #40b882;
    font-size: 33px;
}
.scroll-to-top-btn:hover{  color: #40b882 !important;
    background-color: #ffffff;
    border: 3px solid #40b882;
}
.scroll-to-top-btn span {
    transition: 0.3s;
}
.scroll-to-top-btn:hover span {
    margin-bottom: 6px;
}

.mobbanner{display: none;}
.deskbanner{display: flex;}
.experiencebannermob{display: none;}
@media(max-width:500px){
 .mobbanner{display: block;}
.deskbanner{display: none;}   
.experiencebannerweb{display: none;}
.experiencebannermob{display: block;}
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    padding: 0px 0 5px !important;}

.mobcarousel{
    display: none;

}
@media(max-width:500px){
    .mobcarousel{display: block;}
   .webcarousel{display: none;}   
   }
   .stylenew {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
height: 690px;
    background-color:white; /* You need to define this variable in your CSS */
    border: 2px solid #000;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.24);
    padding: 16px 17px 16px 7px;
  }
  .image {
    opacity: 1;
    display: block;
    width: 90%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
.containernew:hover .image {
    opacity: 0.3;
  }
  
  .containernew:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
  }
  .containernew {
    position: relative;
    width: 50%;
    border: 3px solid #40b882;
  }
  @media(max-width:500px){
     .containernew {
    width: 100%;
  }
  }
  .mapdesign{
    background-color: #40b882;
  }
  
.element {
    /* justify-items: center; */
    /* width: 100%; */
    text-align: -webkit-center;
}
.css-qw3skv-MuiPaper-root-MuiCard-root {
    height: auto !important;
padding-top: 15px;
padding-bottom: 15px;}

#launcher-frame {
right: 15px !important;
bottom: 78px !important;
   
}
.webcarousel .react-multiple-carousel__arrow {display: none !important;}

.pagination{
    display: flex !important;
    justify-content: center !important;
}
        .pagination li{
        margin: 0px 12px !important;
        text-decoration: none !important;
        border: none;
        border-radius: 16px !important;
        /* background-color: #808080ad !important; */
        color: #000 !important;
        cursor: pointer !important;
        font-size: 19px;
        font-weight: 700;
        padding: 0px 11px !important;
        }
.pagination .active{
background-color: #73809c4f !important;
    color: #fff !important;font-weight: 500;}
    .buynowimgdesktop{display: block;}
    .buynowimgmobile{display: none;}

  @media(max-width:500px) {
    .buynowimgdesktop,.linkbtndesktop {
            display: none;
        }
    
        .buynowimgmobile,.linkbtnmobile {
            display: block;
        }
        .stylenew {
            width: 90%;
            height: auto;
        }
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
  }
  
  .loader-content {
    text-align: center;
  }
  .loaderimg{height: 400px;}

.paginationcontainer {
    position: relative;
    /* Adjust this to the desired height of the container */
}

.pagination {
    /* position: absolute; */
    /* bottom: 0; */
    left: 0;
    /* If you want it to be on the left side of the container */
    right: 0;
    /* If you want it to be on the right side of the container */
}
.dashboardwebcarousel{display: block;}
.dashboardmobcarousel,.mobilerequest{display: none;}
@media  (max-width:500px){
.dashboardwebcarousel,.webrequest {
        display: none;
    }

    .dashboardmobcarousel,.mobilerequest {
        display: block;
    }
}
.mobiledetails,.mobiledetailscountry{display: none;}
/* .plandate{width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 3;
    color: rgb(73, 80, 87);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 28px;
        padding: 5px 30px 5px 20px;
} */


.bottomcontainer {
    position: relative;
height: 378px;
}
@media(max-width:500px){
    .bottomcontainer {
    height: 422px;
    }
}

.bottom-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
      margin-left: 5%;
        margin-bottom: 19px;
}
.bottomcontainer2 {
    position: relative;
    height: 435px;
}
.bottom-button2 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 508px;
    margin-bottom: 19px;
        margin-top: 52px;
}
@media(max-width:500px){
    .bottom-button2 {
        padding-left: 50px;
        padding-right: 50px;
        width: 100%;
    }
}
.subscriptiontable thead tr th{padding-top: 10px;padding-bottom: 10px;}
.subscriptiontable tbody tr td{padding-top: 20px;padding-bottom: 20px;text-align: center;}
.subscriptiontable tbody tr {border-bottom: 1px solid #D8DEE7;}
.hr-line{border-top-width: 4px!important;border-color: #005d8d!important; margin-bottom: 20px !important;}


/*  */
.tab-menu {
    display: flex;
  }
  
  .tab-item {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
  }
  
  /* .tab-item.active {
    background-color: #007bff;
    color: #fff;
  } */
  .inner-container._866px {
    max-width: 866px;
  }
  .tab-menu .tab-item.active {
    background-color: #f3f8ff !important;
    color: var(--neutral--800) !important;
}
.flag-dropdown  {
        border-radius: 132px 0px 0px 132px !important;
}
.form-control {
    border-radius: 132px !important;
}
.bordercolor {
    border: 1px solid #004171!important;
}

.hidelogin,.curebaylogomob,.headercart{display: none;}
.frequencytab .active{background-color: #004171;color: #fff;}

.cartprice{    color:#666e82;
    font-size: 24px;
    text-decoration: line-through;font-weight: 600;margin-bottom: 13px;}
    .grid-cols-8 {
        display: grid;
        grid-template-columns: repeat(8);
    }
    .grid-cols-5 {
        display: grid;
        grid-template-columns: repeat(5);
    }
       .logobox {box-shadow: 0 2px 6px rgba(31, 75, 160, .08), 0 -2px 6px rgba(31, 75, 160, .08);
            padding: 29px 10px;
            border-radius: 26px;}
            .listitemcard{box-shadow: 0 2px 6px #1f4ba02b;
                margin-bottom: 20px;
                border-radius: 23px;
            
            }
            .react-multiple-carousel__arrow{z-index: 0 !important;}
    
@media(max-width:500px){
    .hidelogin,.curebaylogomob,.headercart{display: block;} 
    .curebaylogoweb{display: none;}  
    .css-qnoku6-MuiDialogContent-root {
       
        padding: 0rem !important;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    
    }
    .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
       
        width: 100% !important;
        margin: 5px 10px !important;
    }

    .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
        width: 21px !important;
        height: 24px !important;
        margin: 0px !important;
    }

}
.grid-cols-3{
    display: grid;
    grid-template-columns: repeat(3);
}
.grid-cols-1{
    display: grid;
    grid-template-columns: repeat(1);
}
.patnercard{box-shadow: 0 2px 6px rgba(31, 75, 160, .08), 0 -2px 6px rgba(31, 75, 160, .08);
    padding: 33px 16px;
    border-radius: 20px;
}
.paybutton{color: #004171;
    font-weight: 700;
    /* border: 2px solid #004171; */
    /* padding: 6px 10px; */
    /* border-radius: 21px;
    color: #fff; */
}
 .p-carousel .p-carousel-content .p-carousel-next {
   
    color: white;
   
    background-color: #6c757d;
   
}
.p-carousel .p-carousel-content .p-carousel-prev {
    color: white;
   
    background-color: #6c757d;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
   
    width: 297px;
   
}
.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper,.css-oapmtd{
    border-radius: 19px!important;
}
@media(max-width:500px){
    .addcartbtn{margin-left: 0px !important;}
}
.css-6hp17o-MuiList-root-MuiMenu-list ,.css-r8u8y9{
   
    width: 297px;
   
}
.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
    border-radius: 19px!important;
}
.link2:hover,.link2:active{color: white;}
.green-checkbox {
    background-color: red;
  }
  .borderclr{ border: 2px solid blue;}
  .thankubtn{border: 3px solid white;
    font-size: 22px;
    padding: 12px 35px;
    border-radius: 30px;}

    .overflow-y-auto {
        /* This property hides the default scrollbar */
        scrollbar-width: thin; /* "auto" or "thin" or "none" */
      }
      
      .overflow-y-auto::-webkit-scrollbar {
        width: 5px; /* Adjust the width of the scrollbar */
      }
      
      .overflow-y-auto::-webkit-scrollbar-thumb {
        background-color: #42ba85; /* Change the color of the scrollbar thumb */
        border-radius: 10px; /* Rounded corners for the thumb */
      }
      
      .overflow-y-auto::-webkit-scrollbar-track {
        border-radius: 10px; /* Rounded corners for the track */
      }
      

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
     height: 0px!important
  
  
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
     height:0px !important;
  
}
.addvital{
    border: 1px solid #005D8D;
    border-radius: 8px;
    padding: 3px 3px 1px 3px;
    font-size: 11px;
}

    .custom-slide-menu .submenu-item {
        padding-left: 20px; /* Adjust padding to prevent overlap */
      }
      
      .custom-slide-menu .back-button {
        z-index: 10; /* Ensure the back button stays above other elements */
        position: relative;

      }
      .p-slidemenu-backward{
        display: none;
      }
      .widthwebkit{
        width: -webkit-fill-available;
      }
     
.goldcss{
 
        background: linear-gradient(135deg, #B48811 0%, #EBD197 33.82%, #BB9B49 66.09%, #A2790D 100%);
        -webkit-background-clip: text;
        color: transparent;
     

}
.ellitecss{
 
    /* background: linear-gradient(135deg, #B48811 0%, #EBD197 33.82%, #BB9B49 66.09%, #A2790D 100%); */
    background: linear-gradient(0deg, #C8592A 0%, #CF6634 2.24%, #E48E53 9.79%, #F3AA6A 16.71%, #FCBC77 22.71%, #FFC27C 27.12%, #FBBA75 35.06%, #F1A561 47.26%, #E18242 62.11%, #D97233 68.05%, #DD7638 77.09%, #E98346 87.64%, #FD995D 98.9%, #FF9B60 100%);

    -webkit-background-clip: text;
    color: transparent;
 

}
.platinumcss{background: linear-gradient(137.95deg, #3A3A3A 2.28%, #A4A4A4 19.8%, #606060 32.94%, #CECECE 50.16%, #8F8F8F 62.15%, #464646 78.69%, #696969 95.24%);
    -webkit-background-clip: text;
    color: transparent;;
}
/* .border-card {
    border: 2px solid;
    border-image-source: linear-gradient(135deg, #B48811 0%, #EBD197 33.82%, #BB9B49 66.09%, #A2790D 100%);
    border-image-slice: 1;
    border-radius: 20px;
    padding: 1rem;
    background: white;
  } */

.border-card-wrapper {
    border-radius: 20px;
    padding: 2px; /* To ensure the gradient border is visible */
    background: linear-gradient(135deg, #B48811 0%, #EBD197 33.82%, #BB9B49 66.09%, #A2790D 100%);
    display: inline-block;
  }
  .border-card-wrapper2{
    border-radius: 20px;
    padding: 2px; /* To ensure the gradient border is visible */
    background: linear-gradient(137.95deg, #3A3A3A 2.28%, #A4A4A4 19.8%, #606060 32.94%, #CECECE 50.16%, #8F8F8F 62.15%, #464646 78.69%, #696969 95.24%);

    display: inline-block;
  }
  .border-card-wrapper3{
    border-radius: 20px;
    padding: 2px; /* To ensure the gradient border is visible */
   background-color: #025B91;

    display: inline-block;
  }
  
  .border-card {
    border-radius: 18px; /* Slightly less to fit inside the wrapper */
    background: white;
    padding: 16px; /* Inner padding to ensure content does not overlap with border */
    display: flex;
    align-items: center;
  }
  
  .border-card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .border-card-right {
    text-align: right;
    flex-shrink: 0;
  }



  .accordion-section {
    border-radius: 10px;
    background-color: #0070a8;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-content {
    padding: 0px 20px 20px 20px;
    background-color: #fff;
    border-radius: 18px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .benefit-item {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: start;
    padding: 10px 0;
  }
  
  .benefit-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .benefit-item-left, .benefit-item-right {
    width: 45%;
  }
  
  .checkicon {
    margin-right: 10px;
  }
  
  .accordion-icon {
    font-size: 20px;
  }
  .inner-container._1259px {
    max-width: 1259px;
}  
.borderstyle {
    border-bottom: 1px dashed #737373;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  @media (min-width: 640px) {
    .sm\\:grid-cols-3 .borderstyle {
      border-bottom: none;
    }
  
    .sm\\:grid-cols-3 .borderstyle:nth-child(3n+3) {
      border-bottom: 1px dashed #737373;
    }
  }
  
    .bgclrdiv{background-color: #F3F8FF;}
    .border-card-wrapper {
  position: relative;
  border: 1px solid transparent;
}

.border-card-wrapper.border-selected {
  border: 1px solid rgba(180, 136, 17, 1);
  border-radius: 8px;
}
.border-card-wrapper2 {
    position: relative;
    border: 1px solid transparent;
  }
  
  .border-card-wrapper2.border-selected {
    border: 1px solid rgba(180, 136, 17, 1);
    border-radius: 8px;
  }
  .border-card-wrapper3 {
    position: relative;
    border: 1px solid transparent;
  }
  
  .border-card-wrapper3.border-selected {
    border: 1px solid #025B91;
    border-radius: 8px;
  }
  

.border-card {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 177px;
}

.border-card-content {
  flex: 1;
}

.border-card-right {
  flex-shrink: 0;
}

.border-card-selected {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}
.selecticon{background: linear-gradient(135deg, #B48811 0%, #EBD197 33.82%, #BB9B49 66.09%, #A2790D 100%);
    border-radius: 30px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 17px;
}
.selectplatinumicon{
    background: linear-gradient(137.95deg, #3A3A3A 2.28%, #A4A4A4 19.8%, #606060 32.94%, #CECECE 50.16%, #8F8F8F 62.15%, #464646 78.69%, #696969 95.24%);
    border-radius: 30px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 17px;
}
.selecteliteicon{
    background-color: #025B91;
    border-radius: 30px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 17px;
}

.oldplandesign{display: none;}
.packegebgimg{position: absolute;right: 0;height: 357px;
    margin-top: -46px;}

   .carouseldes{position: absolute;
        background-color: #fff;
        padding: 25px 15px;
        border-radius: 17px;
        margin-top: 190px;width: 358px;}
        .carouseldiv2,.accodiandiv2{display: none;}

        .cursor-pointer {
            cursor: pointer;
          }
          
          .popup {
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            height: 100vh;
            justify-content: center;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
          }
          
          .popup-content {
            background-color: white;
            border-radius: 10px;
            padding: 13px 16px;
            position: relative;
            text-align: center;
            width: 900px;
          }
          
          .close-button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
          }
          
          .proceed-button {
            background-color: #007bff;
            border: none;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            margin-top: 20px;
            padding: 10px 20px;
          }
          
         
          
          /* .step-content h3 {
            margin: 0;
          }
          
          .step-content p {
            margin: 5px 0 0 0;
          } */
          .btn-secondary2{border: 1.5px solid var(--neutral--600);
    box-shadow: none;
    color: var(--neutral--600);
    text-align: center;
    transform-style: preserve-3d;
    background-color: #fff;
    border-radius: 96px;
    margin-left: 10px;
    padding: 24px 38px;
    font-size: 18px;
    line-height: 1.112em;
    transition: border-color .3s, transform .3s, background-color .3s, color .3s;}
        @media(max-width:500px){
            .popup-content {
                width: 297px;
              }
            .carouseldiv1,.accodiandiv1{display: none;}
            .carouseldiv2,.accodiandiv2{display: block;}  
            .carouseldes {
                position: absolute;
                background-color: #fff;
                padding: 0px 32px;
                border-radius: 17px;
                margin-top: 135px;
                width: auto;
                margin-left: 17px;
                margin-right: 17px;
            }

            .card.card-link-icon-top {
                padding: 5px 8px !important;
            }
            .packegebgimg {
                height: 120px;
            }
            .border-card {
                min-height: 113px;
            }
            .borderstyle {
                padding-top: 6px;
                padding-bottom: 6px;
            }
            .btn-circle-secondary {
                width: 37px;
                height: 37px;
                min-height: 40px;
                min-width: 28px;
                font-size: 22px;
            }
        }
        .bg-primaryColor {
            --tw-bg-opacity: 1;
            background-color: rgb(0 65 113 / var(--tw-bg-opacity));
        }
        .text-secondaryColor {
            --tw-text-opacity: 1;
            color: rgb(66 186 133 / var(--tw-text-opacity));
        }

       
          
          .care-option {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            padding: 20px;
            /* text-align: center; */
            /* width: 150px; */
            border: 1px solid #0000001a;
            cursor: pointer;
          }
          
          .care-option-icon {
            background-color: #e6effe;
            border-radius: 10%;
            height: 60px;
            width: 60px;
            margin-bottom: 10px;
            display: flex;
            /* align-items: center;
            justify-content: center; */
          }
          
          .care-option-icon.icon-general-care {
            /* Use the appropriate background image or icon for General care */
          }
          
          .care-option-icon.icon-ortho-care {
            /* Use the appropriate background image or icon for Ortho care */
          }
          
          .care-option-icon.icon-cardiac-care {
            /* Use the appropriate background image or icon for Cardiac care */
          }
          
          .care-option-icon.icon-womens-care {
            /* Use the appropriate background image or icon for Women’s care */
          }
          
          .care-option-icon.icon-diabetes-care {
            /* Use the appropriate background image or icon for Diabetes care */
          }
          
          .care-option-name {
       color: #373737;
            font-size: 19px;
            font-weight: 500;
          }
          
          .care-option-arrow {
            font-size: 20px;
            color: #007bff;
          }
          @media(max-width:500){
            .care-option {
               
                padding: 15px;
              }
              
              .care-option-icon {
                height: 45px;
                width: 45px;
              }
              .care-option-name {
                color: #373737;
                     font-size: 16px;
                     font-weight: 500;
                   }
          }


    
