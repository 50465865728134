
.main-table {
    border: none !important;
    border-collapse: collapse;
}

.main-table tr {
    border-top: none;
}

.main-table tr:first-child td {
    border-top: none;
}

.main-table tr:last-child td {
    border-bottom: none;
}
  
.main-table tr td:first-child {
    border-left: none;
}

.main-table tr td:last-child {
    border-right: none;
}

